import React, {useContext, useEffect, useState} from 'react'
import Thermometer from '../../Assets/thermometer.png'
import {useLocation} from 'react-router-dom'
import Pricing from '../../configs/pricing.js'

const Temperature = (props) => {
    const [lot, setLot] = useState()
    const [temp, setTemp] = useState()
    const location = useLocation()

    const getLotId = () => {
        var lot = localStorage.getItem('lot')
        if (!lot || lot===null || lot===undefined || lot==='undefined' || lot==='null'){
            if(props.lot!==null && props.lot!=undefined){
                lot = props.lot
            }else if(props.match!==null && props.match!=undefined && 
                    props.match.params!==null && props.match.params!==undefined && 
                    props.match.params.lot!==null && props.match.params.lot!=undefined){
                    lot = props.match.params.lot
            }else {
                lot = location.search.slice(1).split("&")[0].split("=")[1]
                 if (lot && lot!==null && lot!==undefined && lot!=='undefined' && lot!=='null'){
                    try{
                        parseInt(lot)
                    }catch{
                        lot = undefined
                    }
                }
            }
            if(lot && lot!==null && lot!==undefined && lot!=='undefined' && lot!=='null'){
                if(localStorage.getItem('lot')){
                    localStorage.removeItem("lot")
                }
                setTimeout(()=>{
                    localStorage.setItem('lot', lot)
                }, 10)
            }
        }
        return lot
    }

    const getWeather = (lot) => {
        if(lot!==null && lot!==undefined && lot!=='null' && lot!=='undefined'){
            try{
                fetch(`${Pricing.dynamicPriceAPI}${Pricing.WeatherEndPoint}?lotId=${lot}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "ApiKey" : Pricing.apiKey
                    },
                }).then((res) => res.json())
                    .then((data) => {
                        if(data.isSuccess){
                            const t = data.value.data.values.temperature
                            setTemp(Math.round(parseFloat(t)))
                        }
                        console.log(data.error.name)
                    })
                    .catch(function (error) {
                        console.log("no data")
                    })
            }catch(e){
            }
        }
    }

    useEffect(()=>{
        const getLot = getLotId()
        setLot(getLot)
        getWeather(getLot)
        // const tr = setInterval( () => {
        //     getWeather(getLot)
        // }, (15*60*100))
    }, [])
    
    return(
        <>
            {(lot && lot!==null && lot!==undefined && lot!=='null' && lot!=='undefined' && temp && temp!==null && temp!==undefined) && (
                <div style={{width:'100%', height:'100%', overflow: 'hidden'}}>
                    <div style={{width:'35%', height:'100%', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center', float:'left'}}>
                        <img style={{width:'100%', height:'100%', textAlign:'left'}} id='thermometer' src ={Thermometer} alte='thermometer' />
                    </div>
                    <div style={{width:'65%', height:'100%', float:'left'}}>
                        <div style={{height:'50%', overflow:'hidden'}}>
                            <div style={{height:'100%', float: 'left', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <p style={{height:'100%', fontSize:'20px'}}>
                                    {temp? temp:0}
                                </p>
                            </div>
                            <div style={{height:'100%', float:'left', overflow:'hidden'}}>
                                <div style={{height:'100%', float:'left'}}>
                                    <div style={{height:'5%'}}>
                                    </div>
                                    <div style={{height:'95%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <p style={{height:'100%', fontSize:'10px'}}>
                                            o
                                        </p>
                                    </div>
                                </div>
                                <div style={{height:'100%', float:'left'}}>
                                    <div style={{height:'12%'}}>
                                    </div>
                                    <div style={{height:'88%',display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <p style={{height:'100%', fontSize:'15px'}}>
                                            C
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{height:'50%', overflow:'hidden'}}>
                            <div style={{height:'100%', float: 'left', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <p style={{height:'100%', fontSize:'20px'}}>
                                    {temp? temp+32:32}
                                </p>
                            </div>
                            <div style={{height:'100%', float:'left', overflow:'hidden'}}>
                                <div style={{height:'100%', float:'left'}}>
                                    <div style={{height:'5%'}}>
                                    </div>
                                    <div style={{height:'95%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <p style={{height:'100%', fontSize:'10px'}}>
                                            o
                                        </p>
                                    </div>
                                </div>
                                <div style={{height:'100%', float:'left'}}>
                                    <div style={{height:'12%'}}>
                                    </div>
                                    <div style={{height:'88%',display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <p style={{height:'100%', fontSize:'15px'}}>
                                            F
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            )}
        </>
    )
}

export default Temperature
