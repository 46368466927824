import React, {useState} from 'react'
// import imgLoading from '../../Assets/parkingLoader_02.gif'
import imgLoading from '../../Assets/logo.svg'
import LoadingSpin from '../LoadingSpin/LoadingSpin'

/**
* @author
* @function Loading
**/



const Loading = (props) => {
    const [loading, setLoading] = useState({
        divStyle: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        imageStyle: {
            width: '50%'
        }
    })

  return(
    <div style={loading.divStyle}>
    <img style={loading.imageStyle} src={imgLoading} alt="loading"></img>
    {/* <h4>Please wait...</h4> */}
    <LoadingSpin isLoading={true}/>
    </div>
   )

 }

export default Loading
