// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollPicker{
    border: 1px solid black;
    border-radius: 10px;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}
.scroll_confirm_holder{ 
    width: 100%;
    display: flex;
    justify-content: center;
}

/* .scrollableDatePicker_container{ 
    width: 100%;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
}
#scrollableDatePicker_wrapper{ 
    width: 80%;
    height: 100px;
    border-radius: 10px;
    border: 1px solid black;
    display: flex;
}
.scroll_data{
    width: 25%;
    height: 100%;
    border: 1px solid blue;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
}
ul.ul_list{
    padding-left: 0px !important;
}
.scollableSelection{
    list-style-type: none;
    margin-top: 20px;
} */`, "",{"version":3,"sources":["webpack://./src/Components/ScrollableDatePicker/picker.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA4BG","sourcesContent":[".scrollPicker{\n    border: 1px solid black;\n    border-radius: 10px;\n    width: 95%;\n    margin-left: 2.5%;\n    margin-right: 2.5%;\n}\n.scroll_confirm_holder{ \n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n/* .scrollableDatePicker_container{ \n    width: 100%;\n    margin: 0;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n}\n#scrollableDatePicker_wrapper{ \n    width: 80%;\n    height: 100px;\n    border-radius: 10px;\n    border: 1px solid black;\n    display: flex;\n}\n.scroll_data{\n    width: 25%;\n    height: 100%;\n    border: 1px solid blue;\n    overflow-y: scroll;\n    display: flex;\n    justify-content: center;\n}\nul.ul_list{\n    padding-left: 0px !important;\n}\n.scollableSelection{\n    list-style-type: none;\n    margin-top: 20px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
