// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentButton{
    /* width: 100%;  */
    background-color: #f0f0f3;
    color: red;
    padding: 14px;
    border: 2px solid #f84d4d;
    border-radius: 60px;
    margin-top: 9%;
    box-shadow: 0px 0px 10px rgb(153 152 192 / 40%), 0px -1px 10px #ffffff;
}
.paymentButton_dark{
    /* width: 100%;  */
    padding: 14px;
    border-radius: 7px;
    margin-top: 9%;
    background: #1E2834;
    border: 1px solid #EA4335;
    box-sizing: border-box;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.6), -10px -10px 30px rgba(255, 255, 255, 0.12);
    border-radius: 60px;
}

.paymentSelectionIcons{
    width: 17px;
    height: 17px;
    margin-right: 7px;
    transform: translateY(2px);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/PayOptions/PaymentButtons.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,sEAAsE;AAC1E;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,yFAAyF;IACzF,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,0BAA0B;AAC9B","sourcesContent":[".paymentButton{\n    /* width: 100%;  */\n    background-color: #f0f0f3;\n    color: red;\n    padding: 14px;\n    border: 2px solid #f84d4d;\n    border-radius: 60px;\n    margin-top: 9%;\n    box-shadow: 0px 0px 10px rgb(153 152 192 / 40%), 0px -1px 10px #ffffff;\n}\n.paymentButton_dark{\n    /* width: 100%;  */\n    padding: 14px;\n    border-radius: 7px;\n    margin-top: 9%;\n    background: #1E2834;\n    border: 1px solid #EA4335;\n    box-sizing: border-box;\n    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.6), -10px -10px 30px rgba(255, 255, 255, 0.12);\n    border-radius: 60px;\n}\n\n.paymentSelectionIcons{\n    width: 17px;\n    height: 17px;\n    margin-right: 7px;\n    transform: translateY(2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
