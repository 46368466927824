import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import './App.css';
import GuestDataCapture from './Pages/GuestDataCapture';
import SetTime from './Pages/SetTime';
import WhiteList from './Pages/SetWhiteList';
import { PaymentContext } from './Context/PaymentContext';
import { useState, useMemo, useEffect} from 'react';
import Selectmethod from './Pages/Selectmethod';
import SuccessPage from './Pages/Success';
import { CountDown } from './Pages/CountDown';
import { CountDownSms } from './Pages/CountDownSms';
import { CountDownClient } from './Pages/CountDownClient';
// import {loadStripe} from '@stripe/stripe-js';
//import {Elements} from '@stripe/react-stripe-js';
import StripePayment from './Stripe/Stripe-CC-Payment'
import LongTimeSelections from "./Pages/LongTimeSelection"
import PrivacyPolicy from "./Pages/PrivacyPolicy";


// const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

function App(props) {
  const history = useHistory()

  const [totalbilling, setBilling] = useState()
  const [time, setTime] = useState()
  const [startTime, setStartTime] = useState()
  const [expiredTime, setExpiredTime] = useState()
  const [nightTime, setNightTime] = useState()
  const [nightTimeBilling, setNightTimeBilling] = useState()
  const [dayTime, setDayTime] = useState()
  const [dayTimeBilling, setDayTimeBilling] = useState()
  const [priceServer, setPriceServer] = useState()
  const [timeServer, setTimeServer] = useState()
  const [userInfo, setUserInfo] = useState()
  const [isLoading, setIsLoading,] = useState(false)
  const [darkMode, setDarkMode] = useState(1200)
  const providerValue = useMemo(() => ({
    time, setTime,
    priceServer,setPriceServer,
    timeServer,setTimeServer,
    nightTime, setNightTime,
    nightTimeBilling, setNightTimeBilling,
    dayTime, setDayTime,
    dayTimeBilling, setDayTimeBilling,
    totalbilling, setBilling,
    userInfo, setUserInfo,
    expiredTime, setExpiredTime,
    startTime, setStartTime,
    isLoading, setIsLoading,
    darkMode, setDarkMode
  }), [time, nightTime, nightTimeBilling, dayTime, dayTimeBilling, totalbilling, userInfo, expiredTime, startTime, darkMode,isLoading])
  let inactivityTime = function() {
    let time;
    let lot = localStorage.getItem('lot')
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function logout() {
      alert("session is Expired")
      window.location.href =(`/?id=${lot}`)
    }
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 300000)
    }
  };
  window.onload = function() {
    inactivityTime();
  }

  return (
    // basename={process.env.PUBLIC_URL}    //

  <Router >
  <Switch>
  {/*<Elements stripe={stripePromise}>*/}
    <PaymentContext.Provider value={providerValue}>
    <Route path='/' exact strict component={GuestDataCapture} />
    <Route path='/settime/' exact strict component={SetTime} />
    <Route path='/whitelist/' exact strict component={WhiteList} />
    <Route path='/monthparking/' exact strict component={LongTimeSelections} />
    <Route path='/select-payment/' exact strict component={Selectmethod} />
    <Route path='/remaining-time/' exact strict component={CountDown} />
    <Route path='/remaining-time-client/' exact strict component={CountDownClient} />
    <Route path='/sms/' exact strict component={CountDownSms} />
    <Route path='/checkout/' exact strict component={StripePayment} />
    <Route path='/terms/' exact strict component={PrivacyPolicy} />
    <Route path='/success/' exact strict component={SuccessPage} />
    </PaymentContext.Provider>
    {/*</Elements>*/}
  </Switch>
  </Router>
  )
}
export default App;
