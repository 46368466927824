// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Send_receipt_copy{
    width: 100%;
    margin-top: 25px;
    border: 1px solid gray;
    padding: 10px 0 10px 0;
    border-radius: 10px;
    appearance: none;
    background: none;
}
.exit_container{ 
    width: 100%; 
    display: flex;
    justify-content: flex-end;
}
.close_receipt_btn{
    appearance: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Receipt/Receipt.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IAGnB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;AACA;IAGI,gBAAgB;AACpB","sourcesContent":[".Send_receipt_copy{\n    width: 100%;\n    margin-top: 25px;\n    border: 1px solid gray;\n    padding: 10px 0 10px 0;\n    border-radius: 10px;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    appearance: none;\n    background: none;\n}\n.exit_container{ \n    width: 100%; \n    display: flex;\n    justify-content: flex-end;\n}\n.close_receipt_btn{ \n    -moz-appearance: none;\n    -webkit-appearance: none;\n    appearance: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
