import React from 'react'
import './LoadingSpin.css'

const CircleLoading = (loading) => {
  return (
    <>
      {loading.isLoading && (
        <div className='loading-wrapper'>
          <div className='loading-circle'></div>
        </div>
      )}
    </>
  )
}

export default CircleLoading
