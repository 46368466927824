import { Component } from "react";
import Picker from 'react-scrollable-picker'
import './picker.css'



class ScrollableDatePicker extends Component {
    constructor(props) {
        super(props)
        this.date = new Date()
        this.state = {
            valueGroups: {
                date: '',
                hours: this.date.getHours() < 12 ? this.date.getHours() : this.date.getHours() - 12,
                minutes: this.date.getMinutes(),
                meridian:  this.date.getHours() < 12? 'am' : 'pm',
            },
            optionGroups: {
                date: props.month,
                hours: props.hours,
                minutes: props.minutes===0?'00':props.minutes,
                meridian: [
                    { value: 'am', label: 'am' },
                    { value: 'pm', label: 'pm' },
                ],
            },
        };

    }

    handleChange = (name, value, ) => {
        // console.log(value)
        // console.log(name)
        this.props.expired(name, value, this.state.valueGroups)
        this.setState(({ valueGroups }) => ({
            valueGroups: {
                ...valueGroups,
                [name]: value
            }
        }));
        // console.log(this.state.valueGroups)
    };



    render() {
        return (
            <div className='scrollPicker'>
            <Picker
                optionGroups={this.state.optionGroups}
                valueGroups={this.state.valueGroups}
                onChange={this.handleChange} />
            </div>

        )
    }
}

export default ScrollableDatePicker;
