// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_wrapper{
    width: auto;
    background-color: red;
    border: 1px solid black;
    border-radius: 16px;
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    
}`, "",{"version":3,"sources":["webpack://./src/Components/Notifier/Notifier.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;;AAEtB","sourcesContent":[".notification_wrapper{\n    width: auto;\n    background-color: red;\n    border: 1px solid black;\n    border-radius: 16px;\n    color: white;\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
