import React, {useContext, useEffect, useState} from 'react'
import {PaymentContext} from '../Context/PaymentContext'
import PaymentButtons from '../Components/Buttons/PayOptions/PaymentButtons'
import Google from '../Assets/Google.svg'
import CreditCardIcon from '../Assets/ccIcon.svg'
import Apple from '../Assets/Apple.png'
import Header from '../Components/Header/Header'
import WApple from '../Assets/Apple_logo_white.png'
import Sucess from '../Assets/sucess.png'
import { useHistory } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'




const  SuccessPage = () =>{
const history = useHistory()
const { darkMode } = useContext(PaymentContext)
const [darkModeStyle, setDarkModeStyle] = useState({
    globalContainer: 'global_container',
    color: '#000',
    class: 'paymentButton',
    apple: Apple


})
useEffect(()=>{
    if(darkMode >= 1800 || darkMode <= 600){
        setDarkModeStyle({
            globalContainer: 'global_container_dark',
            color: 'white',
            class: 'paymentButton_dark',
            apple: WApple

        })
    }
}, [])

    const back = () =>{
         history.push('/')
    }

return(
<>
<Header home={false}/>
<div className={`${darkModeStyle.globalContainer} choose_lot`} style={{backgroundColor: '#ff4f4f'}}>
<div className='stacked_container' style={{marginTop:"-100px"}} >
    <img src={Sucess}/>
<h1 style={{color: '#FFF'}} className='global_content_title slim'>AWESOME, PAYMENT WAS SUCESSFUL!</h1>
    <span onClick={back} className={` content-align`}>
            <span   className='confirm_title'>Back</span>
        </span>
<div className='position-center'>
<div className='stacked'>

</div>
</div>

</div>

</div>
<Footer />
</>
)

}

export default  SuccessPage;
