// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plateCapture{
    width: 70px !important;
    height: 70px !important;
    /* background-color: #ff4f4f; */
    transform: translateY(-12px);
}
.confirm_title{
    font-size: 14px;
    color: red;
    font-weight: 700;
    text-decoration: none;
    }

    .securePayment_{
        display: flex;
        flex-direction: row;
    }
    .btn-confirm{
        width: 84% !important;
        border: 1px solid red;
        color: red;
        border-radius: 60PX;
        height: 53px !important;
        margin-top: 10px;
        box-shadow: 10px 10px 30px rgba(174, 174, 192, 0.4), -10px -10px 30px #FFFFFF;
    }
    .btn-confirm-dark{
        width: 84% !important;
        border-radius: 10PX;
        height: 53px !important;
        margin-top: 10px;
        background: #1E2834;
        border: 1.13105px solid #EA4335;
        box-sizing: border-box;
        box-shadow: 11.3105px 11.3105px 33.9316px rgba(0, 0, 0, 0.6), -4.52422px -4.52422px 18.0969px rgba(255, 255, 255, 0.12);
    }

`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/CircleBtn_img/CircleBtnimg.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,+BAA+B;IAC/B,4BAA4B;AAChC;AACA;IACI,eAAe;IACf,UAAU;IACV,gBAAgB;IAChB,qBAAqB;IACrB;;IAEA;QACI,aAAa;QACb,mBAAmB;IACvB;IACA;QACI,qBAAqB;QACrB,qBAAqB;QACrB,UAAU;QACV,mBAAmB;QACnB,uBAAuB;QACvB,gBAAgB;QAChB,6EAA6E;IACjF;IACA;QACI,qBAAqB;QACrB,mBAAmB;QACnB,uBAAuB;QACvB,gBAAgB;QAChB,mBAAmB;QACnB,+BAA+B;QAC/B,sBAAsB;QACtB,uHAAuH;IAC3H","sourcesContent":[".plateCapture{\n    width: 70px !important;\n    height: 70px !important;\n    /* background-color: #ff4f4f; */\n    transform: translateY(-12px);\n}\n.confirm_title{\n    font-size: 14px;\n    color: red;\n    font-weight: 700;\n    text-decoration: none;\n    }\n\n    .securePayment_{\n        display: flex;\n        flex-direction: row;\n    }\n    .btn-confirm{\n        width: 84% !important;\n        border: 1px solid red;\n        color: red;\n        border-radius: 60PX;\n        height: 53px !important;\n        margin-top: 10px;\n        box-shadow: 10px 10px 30px rgba(174, 174, 192, 0.4), -10px -10px 30px #FFFFFF;\n    }\n    .btn-confirm-dark{\n        width: 84% !important;\n        border-radius: 10PX;\n        height: 53px !important;\n        margin-top: 10px;\n        background: #1E2834;\n        border: 1.13105px solid #EA4335;\n        box-sizing: border-box;\n        box-shadow: 11.3105px 11.3105px 33.9316px rgba(0, 0, 0, 0.6), -4.52422px -4.52422px 18.0969px rgba(255, 255, 255, 0.12);\n    }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
