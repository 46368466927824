import HanatechLogo from '../../Assets/Hanatech-Small-Logo.png'
const Footer = () =>{
    return (
        <div style={{color:"#9b9b9b", width:"100%", height:"4%", position:"fixed", left:0, bottom:10, overflow:"hidden"}}>
            <div style={{width:"45%", height:"100%", float:"left"}}>
                <div style={{height:'100%', overflow:'hidden', fontSize:"small"}}>
                    <div style={{height:'100%', float:'left', marginRight:'3px'}}>
                        <img src={HanatechLogo} style={{height:"100%"}}/>
                    </div>
                    <div style={{ height:'100%', float: 'left', display:'flex', flexDirection:'column', marginLeft:'3px', marginRight:'3px'}}>
                        <p style={{margin:'auto'}}>
                            Powerd by
                        </p>
                    </div>
                    <div style={{height:'100%', float: 'left', display:'flex', flexDirection:'column', marginLeft:'3px', marginRight:'3px'}}>
                        <a target='_blank' href='https://Hanatech.ca/' style={{margin:'auto', color:'inherit', textDecoration:'none'}}>
                            Hanatech
                        </a>
                    </div>
                    <div style={{height:'100%', float: 'left', display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                        <p style={{margin:'auto'}}>
                            IoT Inc.
                        </p>
                    </div>
                </div>
            </div>
            <div style={{height:"100%", fontSize:"small", float:"left", display:'flex', flexDirection:'column'}}>
            {/* <div style={{height:"100%", fontSize:"small", textAlign:'center', display:'flex', flexDirection:'column'}}> */}
                <p style={{margin:'auto'}}>
                    Version: {process.env.REACT_APP_VERSION}
                </p>
            </div>
        </div>
    )
}

export default Footer