// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header */
#header{
    width: 100%;
    height: 10vh;
    overflow-x: hidden;
    display: flex;
    background-color: #FFFFFF;

}
#logo_img{
    width:90%;
    margin-top: 10px;
}
#hamburger_container{
    width: 35%;
}
.header_containers{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hamburger_menu{
    width: 100%;
    height: 2px;
    background-color: #585858;
    margin: 3px 0px 0px 0px;
}
.hamburger_menu_dark{
    width: 100%;
    height: 2px;
    margin: 3px 0px 0px 0px;
    background-color: white;
}
.back_button{
    border: 1px solid #585858;
    color: #585858;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    top:7px;
    left: 20px;
    appearance: none;
    background: none;

}
`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA,WAAW;AACX;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,yBAAyB;;AAE7B;AACA;IACI,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,OAAO;IACP,UAAU;IAGV,gBAAgB;IAChB,gBAAgB;;AAEpB","sourcesContent":["/* Header */\n#header{\n    width: 100%;\n    height: 10vh;\n    overflow-x: hidden;\n    display: flex;\n    background-color: #FFFFFF;\n\n}\n#logo_img{\n    width:90%;\n    margin-top: 10px;\n}\n#hamburger_container{\n    width: 35%;\n}\n.header_containers{\n    width: 20%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.logo{\n    width: 55%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.hamburger_menu{\n    width: 100%;\n    height: 2px;\n    background-color: #585858;\n    margin: 3px 0px 0px 0px;\n}\n.hamburger_menu_dark{\n    width: 100%;\n    height: 2px;\n    margin: 3px 0px 0px 0px;\n    background-color: white;\n}\n.back_button{\n    border: 1px solid #585858;\n    color: #585858;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    position: absolute;\n    top:7px;\n    left: 20px;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    background: none;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
