// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.title{
font-size: 10px;
color: #ff4f4f;
font-weight: 700;
}
.title_dark{
    font-size: 10px;
    font-weight: 700;
    color: #EA4335;
}
.content-align{
display: flex;
justify-content: center;
align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/CircleBtns_txt/CircleBtnstxt.css"],"names":[],"mappings":";AACA;AACA,eAAe;AACf,cAAc;AACd,gBAAgB;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;AACA,aAAa;AACb,uBAAuB;AACvB,mBAAmB;AACnB","sourcesContent":["\n.title{\nfont-size: 10px;\ncolor: #ff4f4f;\nfont-weight: 700;\n}\n.title_dark{\n    font-size: 10px;\n    font-weight: 700;\n    color: #EA4335;\n}\n.content-align{\ndisplay: flex;\njustify-content: center;\nalign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
