import React, { useContext, useEffect, useState } from 'react'
import { PaymentContext } from '../Context/PaymentContext'
import "../Pages/pages.css";
import CircleTxtBtns from "../Components/Buttons/CircleBtns_txt/CircleTxtBtns";
import CircleBtnsImg from "../Components/Buttons/CircleBtn_img/CircleBtnsImg";
import Timer from "../Components/Timer/Timer";
import Time from "../Components/Timer/Time";
import MaxCondition from '../Components/max_selection/MaxSelection'
import { useHistory, } from 'react-router'
import { getQueriesForElement } from '@testing-library/dom';
import DailyParking from '../Components/Buttons/24+/DailyParking';
import Header from '../Components/Header/Header';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useLocation,
	useParams
} from "react-router-dom";
import Loading from "../Components/Loading/Loading"
import Footer from '../Components/Footer/Footer';

const SetTime = (props) => {
	const history = useHistory()
	const { darkMode,isLoading, setIsLoading } = useContext(PaymentContext)
	const [darkModeStyle, setDarkModeStyle] = useState({ globalContainer: 'global_container' })
	const [lot, setLot] = useState("")
	const [lotTitle, setLotTitle] = useState("")
	const [type, setType] = useState("")
	const [btnClickUpdate, setBtnClickUpdate] = useState(0.0)
	const [val, setVal] = useState(0.0)
	const mxCond = new MaxCondition()
	const date = new Date()
	const max_min_skip = 0.0417 / 60
	var max_num = date.getHours()
	var max_mins = date.getMinutes()
	const time = new Time()

	const location = useLocation();
	// setLot(location.search.slice(1).split("&")[0].split("=")[1]);	// Time update nested func for button onClick()
	const timeUpdate = (val) => {
		var nValue = parseFloat(val)

			setVal(nValue)
			// check for match
			if (val===btnClickUpdate) {
				reset()
			}
			else (
				setBtnClickUpdate(nValue)
			)



	}

	useEffect(() => {
		setLot(localStorage.getItem('lot'))
		setLotTitle(localStorage.getItem('lotTitle'))
		// setType(location.search.slice(1).split("&")[1].split("=")[1])
		const lot = location.search.slice(1).split("&")[0].split("=")[1];
		localStorage.setItem('max_count', 0)
		localStorage.setItem('backbutton', true)
		localStorage.setItem('max', 0)
		if (darkMode >= 1800 || darkMode <= 600) {
			setDarkModeStyle({
				globalContainer: 'global_container_dark',
			})
		}
		if (btnClickUpdate===0) {
			setBtnClickUpdate(val)
		}
	}, [btnClickUpdate,lot,isLoading])


	const reset = () => {
		setBtnClickUpdate(0.0)
	}


	return (
		<>
		<Header home={false}/>
		{!isLoading && 
			<div style={{backgroundColor:"#F0F0F3",borderRadius: "30px 30px 0px 0px"}} >
				<div id='content_holder'>
					<div className='timer_holder'>
						<div className='lot-info'>
							<h5><strong>LOT:</strong> {lotTitle}</h5>
						</div>
						<div style={{marginTop:"15%"}}>
						<Timer addTime={btnClickUpdate} />
						</div>
					</div>
					{/*<div className='align_timer_btns'>*/}
					{/*	<CircleTxtBtns addTime={timeUpdate} title='-1 Hr' time='-0.041' />*/}
					{/*	 /!*<CircleTxtBtns addTime={timeUpdate} title='Max' time={set_max_skip()} />*!/*/}
					{/*	<CircleTxtBtns addTime={timeUpdate} title='+1 Hr' time='0.0417' />*/}
					{/*	<DailyParking link='/monthparking/'/>*/}
					{/*</div>*/}

					<div className='align_timer_btns btns_img_container' style={{marginTop:"15%"}}>
						{/* <CircleBtnsImg title='' img='' /> */}
						<CircleBtnsImg title='Confirm' img='' class='plateCapture' />
						{/* <CircleBtnsImg title='' img='' /> */}

					</div>
						<br/>
						<br/>
						<br/>
						<br/>
				</div>
			</div>
		}
		{isLoading && 
			<div className={`${darkModeStyle.globalContainer} loading_div`}>
				<div className='content_holder'>
				<Loading />
				</div>
			</div>
		}
		<Footer />		
		</>
	);
}

export default SetTime;

