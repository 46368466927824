// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoadingComponent.css */
.loading-wrapper {
  position:fixed ;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Gray transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Set z-index higher than other controls */
}

.loading-circle {
  border: 10px solid transparent;
  border-top: 10px solid blue; /* White color for the loading circle */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/LoadingSpin/LoadingSpin.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,sBAAsB;EAC5D,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,2CAA2C;AAC5D;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B,EAAE,uCAAuC;EACpE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kCAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* LoadingComponent.css */\n.loading-wrapper {\n  position:fixed ;\n  top: 0;\n  left: 0; \n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.1); /* Gray transparency */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Set z-index higher than other controls */\n}\n\n.loading-circle {\n  border: 10px solid transparent;\n  border-top: 10px solid blue; /* White color for the loading circle */\n  border-radius: 50%;\n  width: 100px;\n  height: 100px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
